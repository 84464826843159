import * as React from "react";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import Headline from "../../components/texts/Headline";
import Button from "../../components/buttons/Button";

const Container = Styled.div`
  margin: 20rem 0 3rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    margin: 15rem 0 8rem 0;
  `}

  ${Media.greaterThan("large")`
    margin: 10rem 0 5rem 0;
  `}

  ${Media.lessThan("medium")`
    margin: 5rem 0 5rem 0;
  `}
`;

const Column = Styled.div`
  ${Media.greaterThan("700px")`
    min-width: 0;
  `}
`;

const ColumnText = Styled(Column)`
    max-width: calc(100% - 30rem);
    padding: 10rem 0 10rem 0;
    margin: auto;

    ${Media.lessThan("large")`
        max-width: 100%;
        padding: 5rem 0 5rem 0;
    `}
`;

const ExtendedHeadline = Styled(Headline)`
  margin-bottom: 0;
  
  ${Media.greaterThan("large")`
    margin-bottom: 5rem;
  `}
`;

const Terms = Styled.p`
    font-size: 1.8rem;
`;

const ExtendedH4 = Styled.h4`
    font-weight: bold;
`;

const ButtonSmall = Styled(Button)`
  height: 3rem;
  font-size: 1.5rem;
`;

const Row = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  font-size: 1.6rem;

  ${Media.greaterThan("large")`
    flex-direction: row;
  `}
`;

const TermsOfService = ({ data }) => (
  <Layout>
    <Meta
      title="Terms Of Service | Uizard"
      description="Explore our terms of service online today and get in touch with the Uizard team if you have any questions about any of our Ts and Cs."
      url="/terms-of-service/"
    />

    <Content>
      <Container>
        <Content>
          <ColumnText>
            <ExtendedHeadline as="h1">Terms Of Service</ExtendedHeadline>

            <Row>
              <span>
                <i>Last updated on September the 5th of 2023</i>
              </span>

              <ButtonSmall
                onClick={() => {
                  window.print();
                }}
                solid
                variant="black"
              >
                Download
              </ButtonSmall>
            </Row>

            <Terms>
              <p>
                These Terms of Service (referred to herein as this "Agreement")
                set forth the terms and conditions that apply to your access and
                use of the internet website owned and operated by UizardApp ApS
                and its affiliates ("Uizard", "we", "our", "us" or "our"), and
                located at <a href="https://uizard.io">uizard.io</a> (the
                "Site") including all subsidiary webpages and access to any
                optimized version of the Site via a wireless device, and access
                and use of the Services available thereon, including, without
                limitation, the Services that enable you to collaborate on
                designing, prototyping, and editing graphical user interfaces
                and design systems which can be automatically exported to
                digital design files, computer code, and other supported formats
                (the "Services"). This Agreement is legally binding between you,
                the person or entity using this Site ("Customer," "you" or
                your), and us.
              </p>
              <p>
                YOU ACCEPT AND AGREE TO THIS AGREEMENT AND CONSENT TO OUR
                PRIVACY POLICY LOCATED AT{" "}
                <a href="https://uizard.io/privacy/">uizard.io/privacy/</a> (THE
                "PRIVACY POLICY"), BY DOING ANY ONE OF THE FOLLOWING ITEMS: (1)
                VISITING THE SITE; (2) MAKING A PURCHASE OR SUBMITTING CONTENT
                THROUGH THE SITE; (3) COMPLETING THE ONLINE APPLICATION PROCESS
                BY CLICKING A BOX INDICATING YOUR ACCEPTANCE; OR (4) BY
                EXECUTING A SUBSCRIPTION SERVICES AGREEMENT THAT REFERENCES THIS
                AGREEMENT.
              </p>
              <p>
                On the Site, we may provide short summaries of the terms and
                conditions in this Agreement. Any such summaries are provided
                only for your convenience, are not legally binding and do not
                modify this Agreement in any way.
              </p>
              <p>
                We may update or modify this Agreement or the Privacy Policy at
                any time and such updated versions will be posted on the Site.
                You are responsible for periodically checking this Agreement and
                Privacy Policy. Your continued use of the Site and/or the
                Services after this Agreement and/or Privacy Policy are updated
                shall be deemed acceptance of any such updated Agreement and/or
                Privacy Policy.
              </p>
              <p>
                This Site and the Services are not designed for or directed at
                children 13 years of age or younger. In addition, in order to
                enter into this Agreement, you must be 18 years old or have
                otherwise reached the legal age of majority in your jurisdiction
                of residence or possess legal parental or guardian consent, and
                are fully able and competent to enter into the terms,
                conditions, obligations, affirmations, representations and
                warranties set forth in this Agreement, and to abide by and
                comply with this Agreement. It is your responsibility to ensure
                that you are legally eligible to enter into this Agreement under
                any laws applicable to you in your jurisdiction of residence or
                otherwise. If you accept this Agreement, you represent that you
                have the capacity to be bound by it.
              </p>
              <p>
                You may not use the Services and may not accept this Agreement
                if (a) you are not of legal age to form a binding contract with
                us, or (b) you are a person barred from receiving the Services
                under the laws of Denmark, the United States or other countries
                including the country in which you are a resident or from which
                you use the Services.
              </p>
              <p>
                Before you continue, you should print or save a local copy of
                this Agreement for your records.
              </p>
              <ExtendedH4 id="1definitions">1. DEFINITIONS.</ExtendedH4>
              <p>
                1.1. "<strong>Affiliate</strong>" is an entity that controls, is
                controlled by or shares common control with Uizard or Customer,
                where such control arises from either (a) a direct or indirect
                ownership interest of more than 50% or (b) the power to direct
                or cause the direction of the management and policies, whether
                through the ownership of voting stock by contract, or otherwise,
                equal to that provided by a direct or indirect ownership of more
                than 50%.
              </p>
              <p>
                1.2. "<strong>Claim</strong>" shall have the meaning set forth
                in Section 10.1.
              </p>
              <p>
                1.3. "<strong>Confidential Information</strong>" shall have the
                meaning set forth in Section 8.2.
              </p>
              <p>
                1.4. "<strong>Customer Data</strong>" means all electronic data,
                content, and information submitted to the Services by you, or by
                a third party on your behalf, or as directed by you.
              </p>
              <p>
                1.5. "<strong>Discloser</strong>" means the party disclosing
                Confidential Information.
              </p>
              <p>
                1.6. "<strong>Equipment</strong>" shall have the meaning set
                forth in Section 4.11.
              </p>
              <p>
                1.7. "<strong>Fees</strong>" means the monthly or annual
                subscription fees for the Services as described in Section 5.1
                and as amended or revised from time to time.
              </p>
              <p>
                1.8. "<strong>Recipient</strong>" means the party receiving
                Confidential Information.
              </p>
              <p>
                1.9. "<strong>Services</strong>" shall have the meaning set
                forth in the introductory paragraph of this Agreement and also
                includes the provision of one or more of the following: (i) the
                Uizard application and (ii) user data and statistics.
              </p>
              <p>
                1.10. "<strong>Service Items</strong>" means all software,
                design files, computer code and other formats supported by us,
                documentation, templates, questionnaires, methodologies, models,
                charts, reports and any other items used to deliver the Services
                or made available to you as a result of the Services.
              </p>
              <p>
                1.11. "<strong>Trial Program</strong>" shall have the meaning
                set forth in Section 5.4 and is separate from the Limited Offer
                as described in Section 5.1.
              </p>
              <p>
                1.12. "<strong>Consumer</strong>" shall have the meaning of a
                person who purchases the Services for personal use.
              </p>
              <ExtendedH4 id="2scope">2. SCOPE.</ExtendedH4>
              <p>
                2.1. Subject to your payment of the Fees, we will provide you
                with access to the Services as set forth in this Agreement.
              </p>
              <ExtendedH4 id="3accounts">3. ACCOUNTS.</ExtendedH4>
              <p>
                3.1. In order to access certain Services, you may be required to
                provide information about yourself (such as identification or
                contact details) as part of the registration process for the
                Services or as part of your continued use of the Services. You
                may also have to create passwords or other forms of
                authentication. You agree to provide true, accurate, current and
                complete information about yourself, and you may not
                misrepresent your profile information. You agree and understand
                that you are responsible for maintaining the confidentiality of
                any such information or passwords. Accordingly, you agree that
                you will be solely responsible to Uizard for all activities that
                occur under your account or any sub-accounts created under your
                account. If you become aware of any unauthorized use of your
                password or of your account, you agree to notify us immediately
                at <a href="mailto:support@uizard.io">support@uizard.io</a>.
              </p>
              <ExtendedH4 id="4uizardservices">4. UIZARD SERVICES.</ExtendedH4>
              <p>
                4.1. Subject to the terms of this Agreement, Uizard hereby
                grants to you a non-exclusive, non-transferable,
                non-sublicenseable, limited license to access and use the
                Services (i) worldwide, (ii) for yours and your Affiliates'
                internal business operations, and (iii) in accordance with the
                terms of this Agreement.
              </p>
              <p>
                4.2. We will (i) provide the Services in a manner consistent
                with general industry standards reasonably applicable to the
                provision thereof; and (ii) use commercially reasonable efforts
                to maintain the security of customer passwords.
              </p>
              <p>4.3. You shall:</p>
              <p>
                4.3.1. prevent unauthorized access to, or use of, the Services,
                and notify us promptly of any such unauthorized access or use,
              </p>
              <p>
                4.3.2. comply with all applicable laws in using the Services,
                and
              </p>
              <p>4.3.3. pay the Fees in a timely manner.</p>
              <p>
                4.4. You will not (i) modify, copy or create derivative works
                based on the Services; (ii) disassemble, reverse engineer, or
                decompile the Services or part thereof, or access them in order
                to copy any ideas, features, content, functions or graphics of
                the Services; (iii) interfere with or disrupt the integrity or
                performance of the Services; (iv) send or store infringing,
                obscene, threatening, libelous, or otherwise unlawful or
                tortuous material, or send or store material in violation of any
                third party's privacy rights via the Services; (v) send or store
                viruses or malicious code via the Services; (vi) attempt to gain
                unauthorized access to the Services or their related software,
                systems, platforms or networks; (vii) use any components
                provided with the Services separately from the Services; or
                (viii) distribute, rent, lease, sublicense or provide the
                Services to any third party or use it in a service bureau,
                outsourcing environment, or for the processing of third party
                data.
              </p>
              <p>
                4.5. We aim to keep the Services available through the internet
                at all hours apart from unavailability due to scheduled
                maintenance or unscheduled emergency maintenance either by us or
                by a third-party provider. Any unavailability will be reduced to
                a minimum.
              </p>
              <p>
                4.6. You accept that unavailability due to circumstances with
                the hosting provider or any other third-party supplier is out of
                our control.
              </p>
              <p>
                4.7. Before you use the Services for commercial or other
                purposes, you shall make yourself familiar with the functions of
                the Services, in particular the creation or the lack of same of
                copyright to any generated computer code or other formats and
                the description on how to keep the uploaded images
                secure/confidential, both when uploading and when processing
                them.
              </p>
              <p>
                4.8. You shall ensure that any output from the Services
                undergoes an adequate quality review before being relied upon.
              </p>
              <p>
                4.9. Your login/account and any sub-login/account that you set
                up is personal and may not be used by anybody else than you or
                any sub-user. You are responsible for any misuse of any login/
                account.
              </p>
              <p>
                4.10. Although we have no obligation to monitor your use of the
                Services, we may do so and may prohibit any use of the Services
                we believe may be (or alleged to be) in violation of this
                Agreement, in particular any attempt to commit a copyright
                infringement with the use of the Services.
              </p>
              <p>
                4.11. You shall be responsible for obtaining and maintaining any
                equipment and ancillary services needed to connect to, access or
                otherwise use the Services, including, without limitation,
                internet access, personal computer with appropriate software,
                including updated operating systems, compatible web browsers and
                the like (collectively the "Equipment"). You shall also be
                responsible for maintaining the security of the Equipment, your
                account, passwords (including, but not limited to,
                administrative and user passwords) and files, and for all uses
                of your account or the Equipment with or without your knowledge
                or consent.
              </p>
              <ExtendedH4 id="5feesandpayment">5. FEES AND PAYMENT.</ExtendedH4>
              <p>
                5.1. The Services are provided to you without charge up to a
                certain limit and with limited features (“Limited Offer”). If
                you exceed the limits of the Limited Offer or require access to
                certain features you will be required to pay for a subscription
                (“Subscription”). Consumers will not be charged a fee for any
                Subscription without the Consumer's specific acceptance of the
                pricing and Terms of Service. Details about the types of
                Subscriptions available including their respective limits,
                available features and Fees payable for these Services are set
                forth on the Site at uizard.io/pricing and may change from time
                to time.. The Fees are payable upfront in monthly or annual
                payments in accordance with the terms of the Subscription you
                have chosen.
              </p>
              <p>
                5.2. You agree to pay all Fees. All prices listed, Fees, charges
                and refunds are issued in USD. You agree and authorize Uizard
                or, any third party payment processor (“Payment Processor”) to
                charge your credit card, debit card or any other payment
                provider (“Payment Method”) as agreed to in writing on a monthly
                or annual basis in accordance with the terms of the Subscription
                you have chosen. You understand and agree that all Payment
                Method information will be saved for future transactions. You
                have the obligation to notify Uizard by email to{" "}
                <a href="mailto:support@uizard.io">support@uizard.io</a> of any
                change in your Payment Method without delay including but not
                limited to the loss or theft or any potential or actual
                unauthorized access or use of your Billing Account (as defined
                below). If you fail to keep us informed and do not provide
                accurate and current information we will continue charging you
                in accordance with the information provided on your Billing
                Account (as defined below).
              </p>
              <p>
                5.3. You will pay or reimburse us or, when required by law, the
                appropriate governmental agency, for taxes of any kind,
                including sales, use, VAT, excise, customs duties, withholding,
                property, and other similar taxes (other than taxes based on our
                net income or arising from the employment relationship between
                us and our personnel) imposed in connection with the fees paid
                for the Services, which are exclusive of these taxes. For
                Consumers, all these taxes, etc. are included in the final price
                when placing an order.
              </p>
              <p>
                5.4. At our discretion, we may offer free or discounted pricing
                for use of the Services (a "Trial Program"). Once the terms of
                any Trial Program have expired, you agree that our normal Fees
                shall apply. Consumers will not be charged a fee without the
                Consumer's specific acceptance of the pricing and Terms of
                Service. You agree to comply with any additional terms,
                restrictions or limitations (including limitations on the total
                amount of usage) we impose in connection with any Trial Program.
                You may not sign-up for multiple accounts in order to receive
                additional benefits under any Trial Program.&nbsp;
              </p>
              <p>
                5.5. No refunds for Fees already paid will be given this
                includes any unused Services such as when your actual usage of
                the Services falls below the purchased amount for your
                Subscription level.
              </p>
              <p>
                5.6. You are entitled to a refund if you cancel your subscription and contact our Customer Support team within 14 days of your purchase. To initiate a refund request, kindly send us an email at {" "}
                <a href="mailto:support@uizard.io">
                  <span>support@uizard.io</span>
                </a>
                .
                Should you suspect a billing error, please reach out to our support team at {" "}
                <a href="mailto:support@uizard.io">
                  <span>support@uizard.io</span>
                </a>.
                We are committed to collaborating with you to resolve any issues effectively.
              </p>
              <p>
                5.7. We may use a Payment Processor to charge you through a
                payment account that is linked to your account (your "Billing
                Account"). The processing of payments from your Billing Account
                will be subject to the terms and conditions and privacy policies
                (if any) of the Payment Processor in addition to this Agreement.
                We take no responsibility for any errors made by the Payment
                Processor with respect of the Fees that areConsumers will not be
                charged a fee without the Consumer's specific acceptance of the
                pricing and Terms of Service due. To this end, we reserve the
                right to correct any errors or oversights that the Payment
                Processor makes regardless of your Billing Account having
                already made payment.
              </p>
              <p>
                5.8. If you fail to pay your Fees on time Uizard will charge you
                late Fees and penalties (“Default Interest”). Uizard reserves
                the right the employ actions they deem necessary in the recovery
                of outstanding Fees under applicable law. You agree to reimburse
                Uizard for all reasonable fees, costs and ancillary expenses
                incurred in the efforts made to collect the outstanding Fees
                including but not limited to the Default Interest.
              </p>
              <ExtendedH4 id="6termandterminationcancellation">
                6. TERM AND TERMINATION; CANCELLATION AND CONSUMERS' RIGHT OF
                WITHDRAWAL.
              </ExtendedH4>
              <p>
                6.1. This Agreement is effective from the date on which you
                first accessed the Services, and shall continue until
                terminated, as set forth below.&nbsp;
              </p>
              <p>
                6.2. Any and all Subscriptions will automatically be extended
                for successive renewal periods and for the same term as the
                Subscription originally selected on your account unless you
                cancel or change your Subscription in accordance with this
                Agreement.
              </p>
              <p>
                6.3. If you wish to cancel your subscription, you can do so by
                logging into your account and proceeding with this option
                through the billing section menu or, by choosing the 'remove
                account' option. You will remain liable for all charges accrued
                on your account up to the time that you downgrade or cancel your
                subscription including full monthly fees for the month in which
                you cancelled.
              </p>
              <p>
                6.4. Upon cancellation of your subscription and at the
                expiration of the term of the Agreement, we are under no
                obligation to maintain or store your Customer Data. We may, at
                our option, either delete your information immediately or retain
                it (in full or in part) in accordance with our processes and
                practices as set out in our Privacy Policy to be found at{" "}
                <a href="https://uizard.io/privacy/">uizard.io/privacy/</a>.
              </p>
              <p>
                6.5. We reserve the right at any time, and without cost, charge
                or liability, to terminate this Agreement at our sole discretion
                for any reason, including, but not limited to, a failure to
                comply with the terms of this Agreement. In addition, we may, in
                our sole discretion, at any time, temporarily or permanently
                suspend access to your account or Services for any violation or
                suspected violation of this Agreement. We reserve the right to
                terminate any portion of the Site or the Services at any time,
                for any reason, with or without notice.
              </p>
              <p>
                6.6. Upon termination pursuant to Section 6.4 above, all rights
                and licenses granted herein will terminate and you will make no
                further use of the Services. No termination will relieve you of
                the obligation to pay any Fees accrued or payable to us. Upon
                written request by you made within 30 days after the effective
                date of termination, we will make available to you a file
                containing Customer Data. After such 30 days period, we shall
                have no obligation to maintain or provide any Customer Data and
                may thereafter delete Customer Data without further notice.
              </p>
              <p>
                6.7. The Consumer acknowledges that the Service contains digital
                content and is performed immediately after placing an order and
                before the ending of the usual 14-day withdrawal period and the
                Consumer acknowledges and accepts that this entails that the
                Consumer loses the right of withdrawal, cf. The Danish Consumer
                Protection Act section 18, 2 (13).
              </p>
              <ExtendedH4 id="7limitedwarranty">
                7. LIMITED WARRANTY.
              </ExtendedH4>
              <p>
                7.1. We warrant to you that the Services will be performed in a
                manner consistent with industry standards reasonably applicable
                to the provision thereof. Your exclusive remedy and our sole
                obligation for breach of the warranty in this Section 7 will be
                our use of commercially reasonable efforts to replace the
                non-conforming portion of the Services within a reasonable
                period of time, or if we cannot replace the Services within such
                time period, then we will refund the amount paid by you for the
                Services, pro-rated from the date of the notice of the claim.
                Your rights and our obligations in this Section 7 are
                conditioned upon you providing us with written notice of the
                claim, a complete description of the alleged defects and a
                specific reference to the part or parts of the Services, as
                applicable, to which such alleged defects are contrary. YOU
                ACKNOWLEDGE THAT, EXCEPT AS OTHERWISE EXPRESSLY PROVIDED HEREIN,
                WE, OUR AFFILIATES AND OUR LICENSORS MAKE NO WARRANTY, EXPRESS,
                IMPLIED OR STATUTORY WITH RESPECT TO THE SERVICES OR USE
                THEREOF. WE, OUR AFFILIATES AND OUR LICENSORS HEREBY EXPRESSLY
                DISCLAIM ALL OTHER WARRANTIES, INCLUDING, WITHOUT LIMITATION,
                ANY WARRANTY THAT SERVICES WILL BE UNINTERRUPTED, ERROR FREE OR
                WITHOUT DELAY, AND THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, NON-INTERFERENCE,
                NON-INFRINGEMENT AND INFORMATION CONTENT.
              </p>
              <ExtendedH4 id="8proprietaryrightsandconfidentiality">
                8. PROPRIETARY RIGHTS AND CONFIDENTIALITY.
              </ExtendedH4>
              <p>
                8.1. Uizard, its Affiliates or licensors retain all right, title
                and interest to the Services and all related intellectual
                property and proprietary rights. The Services are protected by
                applicable copyright, trade secret, industrial and other
                intellectual property laws. Uizard, its Affiliates or licensors
                owns all right, title and interest in Service Items and access
                to and use of the relevant Service Items will be governed by the
                terms of this Agreement. We reserve any rights not expressly
                granted to you.
              </p>
              <p>
                8.2. "Confidential Information" means all proprietary or
                confidential information that is disclosed to the Recipient by
                the Discloser, and includes, among other things (i) any and all
                information relating to products or services provided by the
                Discloser, its customer-related and financial information,
                design files, drawing, software code, flow charts, techniques,
                specifications, development and marketing plans, strategies, and
                forecasts and (ii) as to us and our licensors, the Services.
                Confidential Information does not include information that the
                Recipient can show:&nbsp; (a) was rightfully in the Recipient's
                possession without any obligation of confidentiality before
                receipt from the Discloser; (b) is or becomes a matter of public
                knowledge through no fault of the Recipient; (c) is rightfully
                received by the Recipient from a third party without violation
                of a duty of confidentiality; or (d) is or was independently
                developed by or for the Recipient.
              </p>
              <p>
                8.3. Recipient may not disclose Confidential Information of the
                Discloser to any third party or use the Confidential Information
                in violation of this Agreement. The Recipient (i) will exercise
                the same degree of care and protection with respect to the
                Confidential Information of the Discloser that it exercises with
                respect to its own Confidential Information and (ii) will not,
                either directly or indirectly, disclose, copy, distribute,
                republish, or allow any third party to have access to any
                Confidential Information of the Discloser. Notwithstanding the
                foregoing, Recipient may disclose the Discloser's Confidential
                Information to the Recipient's employees and agents who have a
                need to know provided that such employees and agents have legal
                obligations of confidentiality substantially the same (and in no
                case less protective) as the provisions of this Agreement.
              </p>
              <p>
                8.4. If the Recipient becomes aware of any unauthorized use or
                disclosure of the Discloser's Confidential Information, then the
                Recipient will promptly and fully notify the Discloser of all
                facts known to it concerning such unauthorized use or
                disclosure. In addition, if the Recipient or any of its
                employees or agents are required (by oral questions,
                interrogatories, requests for information, or documents in legal
                proceedings, subpoena, civil investigative demand, or other
                similar process) to disclose any of the Discloser's Confidential
                Information the Recipient will not disclose the Discloser's
                Confidential Information without providing the Discloser with
                commercially reasonable advance prior written notice to allow
                the Discloser to seek a protective order or other appropriate
                remedy or to waive compliance with this provision. In any event,
                the Recipient will exercise its commercially reasonable efforts
                to preserve the confidentiality of the Discloser's Confidential
                Information, including, without limitation, cooperating with the
                Discloser to obtain an appropriate protective order or other
                reliable assurance that confidential treatment will be accorded
                to the Confidential Information.
              </p>
              <p>
                8.5. As between us and you, you own all rights, title and
                interest in and to Customer Data. Customer Data is deemed
                Confidential Information under this Agreement. We will use
                Customer Data only as necessary to provide the Services in
                accordance with this Agreement and to improve the Services in
                accordance with this Agreement. You are responsible for
                complying with all legal and contractual requirements, including
                applicable privacy laws and regulations and your agreements with
                third parties who generate and/or process Customer Data,
                relating to the collection, use, processing and transfer of
                Customer Data. &nbsp;You acknowledge and consent that Services
                provided may require Customer Data to be transferred to a
                country outside of your country or the country where Customer
                Data originated. You are solely responsible for the transmission
                of Customer Data to us and to the Services. You are solely
                responsible for the encryption of any Customer Data.
              </p>
              <p>
                8.6. You may upload images produced on behalf of your own
                customers or images belonging to your own customers provided
                that you abide by this Agreement and provided that you are
                liable for any use of your account. You are only allowed to
                upload images to the Services where you hold the necessary
                rights to do so.
              </p>
              <p>
                8.7. You are aware of and accept that we do not warrant any
                copyright protection to the automatically generated graphical user interfaces,
                prototypes, design systems, design files, computer code or any other supported
                output formats.
              </p>
              <p>
                8.8. To the extent that copyright is vested in graphical user interfaces,
                prototypes, design systems, design files, computer code or other output formats,
                you shall obtain the vested rights to the graphical user interfaces, prototypes,
                design systems, , design files, computer code or other output formats produced by
                the Services in accordance with the uploaded images or screenshots.
              </p>
              <p>
                8.9. You acknowledge that you are the owner of the design
                artifacts you create using the Services to the extent that you are
                the owner or have the right to use the uploaded images, screenshots,
                illustrations, fonts, icons, and brand names.
              </p>
              <p>
                8.10. You understand and accept that the Services does not guarantee
                that the graphical user interfaces, prototypes, design systems, design files,
                computer code or other output formats you generate with the algorithms offered by the
                Services will be unique.
              </p>
              <p>
                8.11. You accept that the aforementioned intellectual property
                rights granted in output are non-exclusive, as we cannot
                guarantee that graphical user interfaces, prototypes, design systems,
                design files, computer code or other output format created, uploaded, and edited by another customer
                will not result in the same output as the output created, uploaded, and edited by you.
                You accept that dual production of the same output is possible, and unless the
                uploaded images or screenshots themselves are infringing third-party rights,
                the identical output is also deemed legal and acceptable.
              </p>
              <p>
                8.12. You agree that we shall have a royalty-free, worldwide,
                transferable, sublicenseable, irrevocable, perpetual license to
                use or&nbsp;incorporate into any Uizard products or services any
                suggestions, enhancement requests, recommendations or other
                feedback provided by you relating to the Services.
              </p>
              <p>
                8.13. You also accept that Uizard may include your company name and logo solely
                to reference you and your company as Customer of the Services for the purpose of
                advertising and publicizing Uizard products and Services including but not limited
                to the Site, in client lists, and in Uizard marketing materials.
                You may revoke your approval to this clause at any time by contacting us at <a href="mailto:support@uizard.io">support@uizard.io</a>.
              </p>
              <ExtendedH4 id="9disclaimersandlimitsonliability">
                9. DISCLAIMERS AND LIMITS ON LIABILITY.
              </ExtendedH4>
              <p>
                9.1. <u>DISCLAIMER OF DAMAGES.</u> EXCEPT FOR A BREACH OF
                SECTION 4.3 AND EACH PARTY'S RESPONSIBILITIES IN SECTION 8,
                NEITHER PARTY OR ITS AFFILIATES ARE LIABLE FOR ANY SPECIAL,
                INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES RELATING
                TO OR ARISING OUT OF THIS AGREEMENT OR THE SERVICES (INCLUDING,
                WITHOUT LIMITATION, LOST PROFITS, LOST COMPUTER USAGE TIME, AND
                DAMAGE TO, OR LOSS OF USE OF, DATA), EVEN IF ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES, AND IRRESPECTIVE OF ANY NEGLIGENCE
                OF A PARTY OR WHETHER SUCH DAMAGES RESULT FROM A CLAIM ARISING
                UNDER TORT OR CONTRACT LAW.
              </p>
              <p>
                9.2. <u>LIMITS ON LIABILITY.</u> EXCEPT FOR A BREACH OF SECTION
                8 AND EACH PARTY'S RESPONSIBILITIES IN SECTION 8, NEITHER PARTY
                OR ITS AFFILIATES SHALL HAVE AGGREGATE LIABILITY ARISING OUT OF
                OR RELATED TO THIS AGREEMENT OR THE SERVICES, WHETHER IN
                CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEEDING
                THE AMOUNTS ACTUALLY PAID BY AND DUE FROM CUSTOMER GIVING RISE
                TO LIABILITY, OR IF YOU HAVE NOT HAD ANY SUCH PAYMENT
                OBLIGATIONS, ONE HUNDRED UNITED STATES DOLLARS ($100).
              </p>
              <ExtendedH4 id="10indemnity">10. INDEMNITY.</ExtendedH4>
              <p>
                10.1. Subject to the terms of this Agreement, if a third party
                asserts a claim against us asserting that your Customer Data or
                your use of the Services in violation of this Agreement violates
                that third-party's patent, trade secret or copyright rights, or
                otherwise harms the third party ("Claim"), you will, at your own
                expense: (a) defend or settle the Claim; and (b) indemnify us
                for any damages finally awarded against us based on the Claim.
              </p>
              <p>
                10.2. You will indemnify and hold harmless Uizard and its
                officers, directors, employees and agents from and against any
                claims, disputes, demands, liabilities, damages, losses, and
                costs and expenses, including, without limitation, reasonable
                legal and accounting fees, arising out of or in any way
                connected with (i) your access to or use of the Services; (ii)
                your Customer Data; or (iii) your violation of this Agreement.
              </p>
              <ExtendedH4 id="11governinglawanddisputeresolution">
                11. GOVERNING LAW, DISPUTE RESOLUTION, AND COMPLAINT PROCEDURE.
              </ExtendedH4>
              <p>
                11.1. A party will provide written notice to the other party of
                any controversy, dispute or claim arising out of or relating to
                this Agreement, or to the formation, interpretation, breach,
                termination, or validity thereof ("Controversy"). The parties
                shall engage in good faith negotiations to resolve the
                Controversy. Only if the Controversy is not resolved through
                good faith negotiations within 15 days of the sending of the
                written notice of Controversy, the Controversy may be submitted
                to litigation. Any dispute arising out of or in connection with
                our relationship, including any disputes regarding the
                existence, validity or termination of this Agreement, shall be
                governed by the laws of the Kingdom of Denmark. The dispute
                shall be subject to simplified arbitration administrated by The
                Danish Institute of Arbitration in accordance with the rules of
                the simplified arbitration procedure adopted by The Danish
                Institute of Arbitration and in force at the time when such
                proceedings are commenced.
              </p>
              <p>
                11.2. The United Nations Convention on Contracts for the
                International Sale of Goods shall not apply to this Agreement.
                Nothing in this Agreement shall be deemed as preventing either
                party from seeking immediate injunctive relief from any court
                having jurisdiction over the parties and the subject matter of
                the dispute.
              </p>
              <p>
                11.3. If you are having trouble using the Service or experience
                any other issues, you may contact our customer service team
                directly through our contact formula at the website. Consumer
                complaints can be submitted to the Danish Center for Complaint
                Resolution and the Consumer Complaints Board:{" "}
                <a href="https://naevneneshus.dk/start-din-klage/center-for-klageloesning-og-forbrugerklagenaevnet/">
                  https://naevneneshus.dk/start-din-klage/center-for-klageloesning-og-forbrugerklagenaevnet/
                </a>
              </p>
              <ExtendedH4 id="12dataprotection">
                12. DATA PROTECTION.
              </ExtendedH4>
              <p>
                12.1. We and you specifically agree that if and when you are
                located in the European Economic Area ("EEA") the terms
                "Personal Data", "Process", "Data Controller", "Special
                Categories of Data", "Processing", "Data Subject", "Third Party
                Processing" and "Data Processor" will have the meanings given to
                them in the EU General Data Protection Regulation (GDPR). In
                respect of Personal Data processed under this Agreement, the
                parties agree that you are the Data Controller and we are the
                Data Processor. The Data Processor agrees that it will only
                collect process and use Personal Data for the sole purpose of
                performance of the Services described hereunder and in
                accordance with the Data Controller's instructions. You
                acknowledge that Personal Data shall not include Special
                Categories of Data. We shall have no liability arising from the
                processing of Personal Data in accordance with your written
                instructions. The Data Processor shall take appropriate
                technical and organizational measures to adequately protect
                Personal Data against (i) unauthorized access, (ii) unauthorized
                disclosure, (iii) misuse, (iv) corruption, and (v) loss, in
                accordance with the requirements of the EU General Data
                Protection Regulation (GDPR) . The Data Processor may transfer
                and/or store Personal Data within the EEA. Our processing of
                data shall be done in accordance with our Privacy Policy which
                is published at{" "}
                <a href="https://uizard.io/privacy/">uizard.io/privacy/</a> as
                amended from time to time.
              </p>
              <ExtendedH4 id="13verification">13. VERIFICATION.</ExtendedH4>
              <p>
                13.1. You agree that we or our agents may monitor the Services
                to ensure your compliance with the terms of this Agreement.
              </p>
              <ExtendedH4 id="141miscellaneousterms">
                14.1. MISCELLANEOUS TERMS.
              </ExtendedH4>
              <p>
                14.1. We are not liable for our failure to perform any of our
                obligations under this Agreement during any period in which
                performance is delayed by you or circumstances beyond our
                reasonable control.
              </p>
              <p>
                14.2. The parties are independent contractors and this Agreement
                does not create a partnership, franchise, joint venture, agency,
                fiduciary, or employment relationship between the parties.
              </p>
              <p>
                14.3. You will receive system notifications from us and our
                licensors, if applicable (i.e. planned downtime notices, etc.).
              </p>
              <p>
                14.4. This Agreement (together with any other written agreement
                between us and you) constitutes the entire agreement between you
                and us and supersedes any prior or contemporaneous negotiations
                or agreements, whether oral or written concerning the Services.
                In the event of any conflict between this Agreement and any
                other written agreement between us and you, such other agreement
                shall prevail.
              </p>
              <p>
                14.5. There are no representations, promises, warranties,
                covenants, or undertakings between the parties other than those
                expressly set forth in this Agreement.
              </p>
              <p>
                14.6. There are no third party beneficiaries to this Agreement;
                you are not entering into a contractual relationship with our
                licensors and our licensors have no liability to you.
              </p>
              <p>
                14.7. We reserve the right, in our sole discretion, to modify or
                replace any or all of the terms of this Agreement or change,
                suspend, or discontinue the Services (including without
                limitation, the availability of any feature, database, or
                content), with or without notice. If we make material changes to
                this Agreement that negatively affect you, or materially reduce
                the Services, we will notify you by posting a notice or new
                version of this Agreement on the Site, or by sending you notice
                through the Services, via email or by another appropriate means
                of electronic communication. We may also impose limits on
                certain features and services or restrict your access to parts
                or all of the Services without notice or liability. While we
                will timely provide notice of material modifications, it is also
                your responsibility to check this Agreement periodically for
                changes. Your continued use of the Services following
                notification of any changes to this Agreement constitutes
                acceptance of those changes, which will apply to your continued
                use of the Services going forward. Your use of the Services is
                subject to the Agreement in effect at the time of such use.
                Irrespective of the above, the terms of the Agreement for
                Consumers will not be changed during the term of the Agreement
                to the detriment of the Consumer. Any such change will be made
                with due notice and with effect from the next term at the
                earliest making it possible for the Consumer to withdraw from
                the Agreement.
              </p>
              <p>
                14.8. This Agreement and the rights hereunder are not
                transferable or assignable by you (and any attempted assignment
                will be void) without our prior written consent, except to a
                person or entity who acquires all or substantially all of your
                assets or business, whether by sale, merger or otherwise. We may
                assign or transfer this Agreement without your consent. Subject
                to the foregoing, this Agreement will bind and inure to the
                benefit of the parties, their respective successors and
                permitted assigns.
              </p>
              <ExtendedH4 id="15generalprohibitions">
                15. GENERAL PROHIBITIONS.
              </ExtendedH4>
              <p>15.1. You agree not to do any of the following:</p>
              <p>
                15.1.1. Post, upload, publish, submit or transmit any Customer
                Data that: (i) infringes, misappropriates or violates a third
                party's patent, copyright, trademark, trade secret, moral rights
                or other intellectual property rights, or rights of publicity or
                privacy; (ii) violates, or encourages any conduct that would
                violate, any applicable law or regulation or would give rise to
                civil liability; (iii) is fraudulent, false, misleading or
                deceptive; (iv) is defamatory, obscene, vulgar or offensive; (v)
                promotes discrimination, bigotry, racism, hatred, harassment or
                harm against any individual or group; (vi) is violent or
                threatening or promotes violence or actions that are threatening
                to any person or entity; or (vii) promotes illegal or harmful
                activities or substances;
              </p>
              <p>
                15.1.2. Access, tamper with, or use non-public areas of the
                Services, Uizard's computer systems, or the technical delivery
                systems of Uizard's providers;
              </p>
              <p>
                15.1.3. Attempt to probe, scan or test the vulnerability of any
                Uizard system or network or breach any security or
                authentication measures;
              </p>
              <p>
                15.1.4. Avoid, bypass, remove, deactivate, impair, descramble or
                otherwise circumvent any technological measure implemented by
                Uizard or any of Uizard's providers or any other third party
                (including another user) to protect the Services or Customer
                Data;
              </p>
              <p>
                15.1.5. Attempt to access or search the Services or Customer
                Data or download Customer Data from the Services through the use
                of any engine, software, tool, agent, device or mechanism
                (including spiders, robots, crawlers, data mining tools or the
                like) other than the software and/or search agents provided by
                Uizard or other generally available third party web browsers;
              </p>
              <p>
                15.1.6. Send any unsolicited or unauthorized advertising,
                promotional materials, email, junk mail, spam, chain letters or
                other form of solicitation;
              </p>
              <p>
                15.1.7. Forge any TCP/IP packet header or any part of the header
                information in any email or newsgroup posting, or in any way use
                the Services to send altered, deceptive or false
                source-identifying information;
              </p>
              <p>
                15.1.8. Interfere with, or attempt to interfere with, the access
                of any user, host or network, including, without limitation,
                sending a virus, overloading, flooding, spamming, or
                mail-bombing the Services;
              </p>
              <p>
                15.1.9. Encourage or enable any other individual to do any of
                the foregoing.
              </p>
              <ExtendedH4 id="16companydetails">
                16. COMPANY DETAILS AND CONTACT INFORMATION.
              </ExtendedH4>
              <p>
                <strong>UizardApp ApS</strong>
              </p>
              <p>
                <strong>Address:</strong> Vestergade 29, 1456 Copenhagen,
                Denmark
              </p>
              <p>
                <strong>CVR:</strong> 40868445
              </p>
              <p>
                <strong>Email:</strong> support@uizard.io
              </p>
            </Terms>
          </ColumnText>
        </Content>
      </Container>
    </Content>
  </Layout>
);

export default TermsOfService;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
